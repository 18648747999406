
<template>
    <transition-group tag="div" name="" class="registration_form col-xl-4 col-lg-4 col-sm-6 col-sm-12">
        <div key="step1" class="form-step">
            <p class="form-title">Copil</p>
            <input class="col1" type="text" style="width: 275px;" v-model="form.name" placeholder="Nume copil">
          <span v-show="display_errors && (!form.name || form.name.trim().length==0)" class="error"
                style="display:none">Rugăm să completați câmpul ”Nume”</span>
          <div class="gs">
            <div class="arow">
                    <img src="@/assets/img/arow-down.svg" alt="#" class="imgarow" >
                </div>
                <select class="col2" v-model="form.age" style="width: 275px;">
                    <option value="" selected>Vârsta</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                </select>
            </div>
            <span v-show="display_errors && (!form.age || form.age.trim().length==0)" class="error" style="display:none">Vă rugăm să indicați vârsta (2 cazuri)</span>
            <div class="gs">
                <div class="arow">
                    <img src="@/assets/img/arow-down.svg" alt="#" class="imgarow">
                </div>
                <select class="col3" v-model="form.goal" style="width: 275px;">
                    <option value="" id="childGoal" selected >Scop</option>
                    <option value="Dezvoltarea inteligenței">Dezvoltarea inteligenței</option>
                    <option value="Ridicarea nivelului de joc">Ridicarea nivelului de joc</option>
                    <option value="Îmi place să joc șah">Îmi place să joc șah</option>
                    <option value="Pregătirea pentru turnee">Pregătirea pentru turnee</option>
                    <option value="Altul">Altul</option>
                </select>
            </div>
            <span v-show="display_errors && (!form.goal || form.goal.trim().length==0)" class="error" style="display:none">Rugăm să completați câmpul ”Scop”</span>

            <div v-if="isMobile()">
              <p class="form-title">Părinte</p>
              <input class="col1" type="text" style="width: 275px;" placeholder="Nume Părinte" v-model="form.parent_name">
              <span v-show="display_errors && (!form.parent_name || form.parent_name.trim().length===0)" class="error" style="display:none">Vă rugăm să indicați numele părintelui</span>
              <input class="col1" id="parentEmail" type="email" style="margin-top: 10px;width: 275px;" placeholder="Email" v-model="form.email">
              <span v-show="display_errors && (!form.email || form.email.trim().length===0 || !email_passed)" class="error" style="display:none">Adresa de e-mail trebuie completată</span>

              <vue-tel-input style="margin-top: 10px;width: 275px;" id="parentPhone" :default-country="'RO'" :preferred-countries="['RO', 'MD']"
                             v-model="form.phone"
                             class="col1 phone_number">
                <template #arrow-icon="{ open }">
                  <span>{{ open ? '▲' : '▼' }}</span>
                </template>
              </vue-tel-input>

              <span v-show="display_errors && (!/^(\+\d{11,13})$/.test(this.form.phone))" class="error" style="display:none">Câmpul de telefon trebuie completat</span>

              <div class="button form-button" v-if="loading" type="button" style="width: 275px;">
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              </div>

              <button :class="btn_class" :disabled="disabledStatus" id="submitBtn" v-if="!loading" @click="send" style="width: 275px;">Mai departe</button>
            </div>

        </div>
        <div key="step2" class="form-step" v-if="!isMobile()">
            <p class="form-title">Părinte</p>
            <input class="col1" type="text" style="width: 275px;" placeholder="Nume Părinte" v-model="form.parent_name">
            <span v-show="display_errors && (!form.parent_name || form.parent_name.trim().length===0)" class="error" style="display:none">Vă rugăm să indicați numele părintelui</span>
            <input class="col1" id="parentEmail" type="email" style="margin-top: 10px;width: 275px;" placeholder="Email" v-model="form.email">
            <span v-show="display_errors && (!form.email || form.email.trim().length===0 || !email_passed)" class="error" style="display:none">Adresa de e-mail trebuie completată</span>

              <vue-tel-input style="margin-top: 10px;width: 275px;" id="parentPhone" :default-country="'MD'" :preferred-countries="['RO', 'MD']"
                  v-model="form.phone"
                  class="col1 phone_number">
                  <template #arrow-icon="{ open }">
                    <span>{{ open ? '▲' : '▼' }}</span>
                  </template>
              </vue-tel-input>

            <span v-show="display_errors && (!/^(\+\d{11,13})$/.test(this.form.phone))" class="error" style="display:none">Câmpul de telefon trebuie completat</span>

            <div class="button form-button" v-if="loading" type="button" style="width: 275px;">
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>

          <button :class="btn_class" :disabled="disabledStatus" id="submitBtn" v-if="!loading" @click="send" style="width: 275px;">Mai departe</button>

        </div>
        <div key="error">
        <Popup :show="display_error" @closeEvent="display_error=false">
            <h2 v-html="error_heading"></h2>
            <p v-html="error_text"></p>
        </Popup>
        </div>
        </transition-group>
</template>

<style lang="scss">
    .registration_form{
        position: relative;
        display: flex;
        .form-title{
            font-family: FuturaPT;
            font-weight: bold;
            font-size: 24px;
            line-height: 31px;
            display: flex;
            color: #2f2f2f;
            margin-left: 120px;
            margin-bottom: 1em;
        }
    }
</style>

<script>
import i18n from '@/i18n';
import {rest} from "@/utils/utils";
import Popup from '@/views/components/Popup/Popup.vue';
import env from '@/enviroment';

export default {
  name: 'RegistrationForm',
  components: {
    Popup
  },
  data: function () {
    return {
      step: 1,
      display_errors: false,
      btn_class: "button form-button-disabled",
      disabledStatus: true,
      form: {
        name: "",
        age: "",
        dob: "",
        goal: "",
        parent_name: "",
        email: "",
        phone: "",
      },
      loading: false,
      error_buttons: [],
      display_error: false,
      error_heading: "",
      error_text: "",
    }
  },
  methods: {
    send() {
      var body = {
        data: this.form,
        return: typeof this.$route.query.return != "undefined" ? encodeURIComponent(this.$route.query.return) : null
      };
      console.log(this.form.phone);
      this.loading = true;
      var p = new URLSearchParams(window.location.search);
      if (p.get("referral")) {
        body.data.referral = p.get("referral");
      }
      rest.call("register", body, "POST", false).then((response) => {
        this.loading = false;
        if (response && response.status) {
          if (response.status === "success") {
            if (response.code) {
              switch (response.code) {
                case "412":
                  this.error_buttons = [];
                  this.display_error = true;
                  this.error_heading = "Loghează-te";
                  this.error_text = "Un astfel de utilizator este deja înregistrat, vă rugăm să introduceți contul folosind numele de utilizator și parola";
                  this.error_buttons.push({
                    label: "OK",
                    action: () => {
                      this.display_error = false;
                      return true;
                    },
                  });
                  break;
              }
            } else if (response.redirect) {
              window.location.href = response.redirect;
              return;
            }
          }
        }
      });
    },
    isMobile () {
      return (window.innerWidth < 600);
    },
    setButtonStatus() {
      if (this.form_valid) {
        this.btn_class = "button form-button";
        this.disabledStatus = false
      } else {
        this.btn_class = "button form-button-disabled";
        this.disabledStatus = true
      }
    },
    form_valid() {
      if (this.form.name != '' && this.form.age != '' && this.form.goal != '' && this.form.parent_name != '' && this.form.email != '' && this.form.phone != '') {
        this.setButtonStatus();
        return true
      } else {
        return false
      }
    }
  },
  computed: {
    url_prefix() {
      return (i18n.locale == "ro" ? "" : "/" + i18n.locale);
    },
    step1_passed() {
      if (!this.form.name || this.form.name.toString().trim().length == 0) return false;
      if (!this.form.age || this.form.age.toString().trim().length == 0) return false;
      if (!this.form.goal || this.form.goal.toString().trim().length == 0) return false;
      console.log('Step 1 passed successfully');
      return true;
    },
    step2_passed() {
      if (!this.form.parent_name || this.form.parent_name.toString().trim().length == 0) return false;
      if (!this.form.email || this.form.email.toString().trim().length == 0 || /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.form.email) == false) return false;
      if (!this.form.phone || !/^(\+\d{11,13})$/.test(this.form.phone)) return false;
      console.log('Step 2 passed successfully');
      return true;
    },
    v() {
      return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.form.email) == true;
    },
  },
  watch: {
    form: {
      handler() {
        this.form_valid();
      },
      deep: true,
    }
  },
}


</script>


